#top {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  opacity: 1;
  width: 100%;
  .logo-oem-white {
    display: none;
  }
  .container .row > * {
    height: 75px;
  }
  &.can-animate {
    transition: all 0.3s;
  }
  nav {
    width: 100%;
    max-width: 800px;
  }
  .nav-link {
    font: normal normal 600 14px/18px Montserrat;
    letter-spacing: 0;
    color: #003554;
    line-height: 54px;
    padding: .5rem 0.5rem;
    @media(min-width: 1200px) {
      padding: .5rem 1rem;
    }
  }
}
body.sticky-menu {
  #top {
    position: fixed;
    top: 0 !important;
  }
}

@media(max-width: 991px) {
  body #top {
    position: fixed !important;
    top: 0 !important;
  }
}

#top-container {
  min-height: 75px;
  z-index:20;
  position: relative;
}
#menu-mobile {
  z-index:10;
  position: fixed;
  top: 0;
  right:-100%;
  height: 100vh;
  padding-top: 85px;
  transition: all 0.4s;
  max-width: 235px;
  background: #fff;
  .nav-item {
    width: 100%;
  }
  .nav-link {
    padding: 18px 27px;
    display: block;
    font: normal normal 600 14px/18px Montserrat;
    letter-spacing: 0;
    color: #003554;
    width: 100%;
  }
}
.nav-link {
  border-bottom: 4px solid transparent;
  &.active {
    border-bottom: 4px solid #5DB035;
  }
}
body:not(.sticky-menu) {
  #top {
    .nav-link.active {
      border-bottom: 4px solid transparent;
    }
  }
}
@media(max-width: 991px) {
  #top:not(.can-animate) {
    .nav-item:first-of-type .nav-link {
      border-bottom: 4px solid #5DB035;
    }
  }
}

body.mobile-menu-visible {
  &:before {
    right:0;
  }
  #menu-mobile {
    right: 0;
    z-index:5;
  }
}

a.hamburger {
  display: inline-block;
  width: 38px;
  cursor: pointer;
  padding: 5px;
  span {
    display: block;
    margin-bottom: 4px;
    background: #003554 0% 0% no-repeat padding-box;
    border-radius: 2px;
    height: 4px;
    opacity: 1;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media(min-width: 992px) {
  body:not(.sticky-menu) {
    #top-container {
      #top {
        background: transparent;
        box-shadow: none;
        .nav-link {
          font: normal normal 600 14px/18px Montserrat;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }
      .logo-oem-white {
        display: block;
      }
      .logo-oem-black {
        display: none;
      }
    }
  }
  .top-spacer {
    height: 75px;
  }
}