#o-nas {
  min-height: initial;
  font: normal normal normal 16px/22px Lato;
  letter-spacing: 0px;
  color: #464646;
  position:relative;
  .logo {
    width: 210px;
  }
  p.tauron {
    padding: 24px 0 21px 80px;
    background: url(/img/o-nas/logo-tauron.png) no-repeat 5px 9px;
  }
  h2 {
    font: normal normal 600 20px/30px Montserrat;
    letter-spacing: 0px;
    color: #003554;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      min-height: 36px;
      padding-left: 50px;
      margin-bottom: 15px;
      @media (min-width: 992px) {
        padding-left: 62px;
      }
      background: url(/img/o-nas/tick.svg) no-repeat 0 2px;
    }
  }

  .bg {
      background: url(/img/o-nas/bg.jpg) no-repeat center;
      background-size: cover;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      @media(min-width: 992px) {
        top: 0;
        width: 50%;
        left: 50%;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
      }
  }
  .second-column {
    .bg {
      @media (min-width: 992px) {
        display: none;
      }
      background: url(/img/o-nas/bg.jpg) no-repeat center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0%;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
      }
    }
  }
  .right-column {
    h2 {
      font: normal normal bold 14px/20px Montserrat;
      letter-spacing: 0px;
      color: #FFFFFF;
      @media(min-width:992px) {
        font: normal normal bold 22px/28px Montserrat;
      }
    }
    h3 {
      font: normal normal normal 14px/20px Montserrat;
      letter-spacing: 0px;
      color: #5DB035;
      @media(min-width:992px) {
        font: normal normal normal 22px/28px Montserrat;
      }
    }
    h4 {
      font: normal normal bold 14px/20px Montserrat;
      letter-spacing: 0px;
      color: #FFFFFF;
      @media(min-width:992px) {
        font: normal normal bold 16px/28px Montserrat;
      }
    }
  }
}
