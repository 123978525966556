#galeria {
  min-height: initial;
  background: url(/img/galeria/bg.jpg) no-repeat center center;
  background-size: cover;
  position:relative;

  padding: 42px 0;
  @media(min-width: 992px) {
    padding: 72px 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/img/galeria/bg-overlay.svg) repeat;
    opacity: 0.9;
  }

  h2 {
    font: normal normal 600 20px/26px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    @media(min-width: 992px) {
      font: normal normal 600 24px/30px Montserrat;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
  }
  .swiper-slide {
    padding:0 5px;
    .box {
      max-width: 320px;
      height: 195px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      border: 1px solid #fff;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

  }
  .swiper-button-prev {
    background: url(/img/glowne-zalety/arrow-green-left.svg) no-repeat;
    left: -2px;
    top: 60%;
    position: absolute;
  }

  .swiper-button-next {
    background: url(/img/glowne-zalety/arrow-green-right.svg) no-repeat;
    right: -2px;
    top: 60%;
    position: absolute;
  }

  .gallery-swiper {
    > .swiper-button-prev, > .swiper-button-next {
      display: none;
    }
    @media(min-width: 992px) {
      max-width: 1140px;
      margin: 0 auto;
      position:relative;
      > .swiper-button-prev {
        left: 2px;
      }
      > .swiper-button-next {
        right: 2px;
      }
      > .swiper-button-prev, .swiper-button-next {
        display: inline-block;
      }
      .swiper-slide {
        .swiper-button-next, .swiper-button-prev {
          display: none;
        }
      }
    }
    @media(min-width: 1140px) {
      > .swiper-button-prev {
        left: 28px;
      }
      > .swiper-button-next {
        right: 28px;
      }
    }
  }

  video {
    width: 100%;
    max-width: 320px;
    border:1px solid #fff;
    margin-bottom: 40px;
    @media(min-width: 992px) {
      margin: 0 15px 40px;
    }
  }
}
