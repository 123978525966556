#o-produkcie {
  background: #fff;
  position:relative;
  min-height: initial;
  .left-content {
    padding-top: 35px;
    padding-bottom: 35px;
    @media (min-width: 992px) {
      padding-top: 73px;
      padding-bottom: 73px;
    }
  }
  #video-container {
    position:relative;
    min-height: 460px;
    @media(min-width:992px) {
      left:50%;
      top:0;
      position:absolute;
      min-height: 100%;
    }
    min-width: 50%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    overflow: hidden;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      z-index: 0;
    }
  }
  h2 {
    font: normal normal 600 24px/30px Montserrat;
    letter-spacing: 0px;
    color: #003554;
  }
}