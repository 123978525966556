#korzysci {
  background: #fff;
  position:relative;
  min-height: initial;
  h2 {
    font: normal normal 600 24px/30px Montserrat;
    letter-spacing: 0px;
    color: #003554;
  }
  .bg {
    background: url(/img/korzysci/bg.svg) no-repeat center 20px;
    background-size: 320px 296px;
    padding-top: 360px;
    @media (min-width: 992px) {
      padding-top: 0;
      background-size: 382px 354px;
      min-height: 400px;
      background-position: center;
    }
    .mobile {
      img {
        max-width: 100%;
        margin: 0 auto 15px;
      }
      h2 {
        text-align: center;
        font: normal normal 600 14px/22px Montserrat;
        letter-spacing: 0px;
        color: #003554;
        max-width: 140px;
        margin: 0 auto;
      }
      .col {
        margin-bottom: 41px;
      }
    }
    .desktop {
      img {
        max-width: 100%;
        margin: 0 18px;
      }
      h2 {
        font: normal normal 600 14px/22px Montserrat;
        letter-spacing: 0px;
        color: #003554;
        max-width: 160px;
      }
    }
  }
}

span.dot {
  font-weight: bold;
  font-size: 20px;
  line-height: 14px;
}