#kalkulator {
  min-height: initial;
  padding: 60px 0;
  * {
    font: normal normal normal 16px/22px Montserrat;
    color: #464646;
  }
  h2 {
    font: normal normal 600 20px/30px Montserrat;
    letter-spacing: 0;
    color: #003554;
  }
  h3, .blue-label, .blue-label span {
    font: normal normal 600 16px/22px Montserrat;
    letter-spacing: 0;
    color: #003554;
    &.big, &.big span {
      font: normal normal 600 20px/25px Montserrat;
      @media(min-width:992px) {
        font: normal normal 600 24px/25px Montserrat;
      }
    }
  }
  strong {
    font-weight: bold;
  }
  ul {
    li {
      padding-left: 22px;
      background: url(/img/kalkulator/green-square.svg) no-repeat 5px 9px;
    }
  }
  p.disclaimer {
    font: normal normal normal 12px/16px Lato;
    letter-spacing: 0;
    color: #8B8B8B;
  }
  .noUi-tooltip {
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #003554;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border: 1px solid #00000000;
    padding: 6px 10px;
    transform: translate(-50%, 8px);
    border-radius: 6px;
    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
  .noUi-handle:before, .noUi-handle:after {
    display: none;
  }
  .noUi-horizontal .noUi-handle {
    width: 58px;
    height: 58px;
    right: -29px;
    top: -25px;
    border:0;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    &:focus {
      border:0;
      outline: none;
    }
  }
  .noUi-touch-area {
    &:focus {
      border:0;
      outline: none;
    }
    background: url(/img/kalkulator/slider-bullet.png) no-repeat center;
    background-size: cover;
    height: 58px;
    width: 58px;
  }

  #slider {
    height: 12px;
    border: 4px solid white;
    background: #003554;
    border-radius: 6px;
  }
  .noUi-connect {
    background: #5DB035;
  }
}

