@import "swiper-bundle";
@import "aos";
@import "nouislider";
@import "reset.scss";
@import "rlightbox.scss";


body {
  overflow-x:hidden;
  //font-family: 'Montserrat', sans-serif;
  //font-family: 'Lato', sans-serif;
  font: normal normal normal 16px/22px Lato;
  background: #f5f5f5;
  font-family: 'Open Sans', sans-serif;
  color: #464646;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Slab', serif;
  }
  &:before {
    transition: all 0.3s;
    content: '';
    position:fixed;
    top:0;
    right:-100vw;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    z-index:1;
  }
}

section {
  min-height: 100vh;
}

@import "top.scss";
@import "home.scss";
@import "o-produkcie.scss";
@import "glowne-zalety.scss";
@import "korzysci.scss";
@import "kalkulator.scss";
@import "galeria.scss";
@import "o-nas.scss";

.swiper-button-prev::after, .swiper-button-next::after {
  content: '';
}
@import "footer.scss";
