#glowne-zalety {
  min-height: initial;
  .desktop {
    height: 100%;
    min-height: 760px;
    background: url(/img/glowne-zalety/bg-desktop.svg) no-repeat center;
    background-size: cover;
    .bg {
      background: url(/img/glowne-zalety/panel-desktop.png) no-repeat center 172px;
      min-height: 760px;
    }

    .grid-container {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto;
      gap: 0px 0px;
      grid-template-areas:
        "box1 box1 box1 box1 . box2 box2 box2 . box3 box3 box3"
        "box1 box1 box1 box1 . box2 box2 box2 . box3 box3 box3"
        ". box4 box4 box4 box4 box4 . box5 box5 box5 box5 box5"
        ". box4 box4 box4 box4 box4 . box5 box5 box5 box5 box5"
        "box6 box6 box6 box6 box6 box6 . box7 box7 box7 box7 box7"
        "box6 box6 box6 box6 box6 box6 . box7 box7 box7 box7 box7"
        ". . . . . . . box9 box9 box9 box9 ."
        "box8 box8 box8 box8 box8 . . box9 box9 box9 box9 ."
        "box8 box8 box8 box8 box8 . box10 box10 box10 box10 box10 box10"
        "download download download download download . box10 box10 box10 box10 box10 box10"
        "download download download download download . . . . . . ."
        ". . . . . . . . . . . .";
    }
    .box1 { grid-area: box1; }
    .box2 { grid-area: box2; }
    .box3 { grid-area: box3; }
    .box4 { grid-area: box4; }
    .box5 { grid-area: box5; }
    .box6 { grid-area: box6; }
    .box7 { grid-area: box7; }
    .box8 { grid-area: box8; }
    .box9 { grid-area: box9; }
    .box10 { grid-area: box10; }
    .download { grid-area: download; }
    .box1 {
      transform: translateY(30px);
    }
    .box2 {
      .box-container {
        .box {
          max-width: 240px;
        }
      }
    }
    .box3 {
      .box-container {
        .box {
          max-width: 255px;
        }
      }
    }
    .box4 {
      transform: translateY(30px);
    }
    .box6 {
      transform: translateY(40px);
      .box-container {
        .box {
          max-width: 350px;
        }
      }
    }
    .box8 {
      margin-right: 34px;
      transform: translateY(50px);
      .box-container {
        .box {
          max-width: 250px;
        }
      }
    }
    .box10 {
      padding-left: 80px;
      transform: translateX(-50px);
    }
    .download {
      transform: translateY(30px);
    }
    .box-container {
      position: relative;
      .box {
        z-index: 2;
        padding: 13px 15px;
        width:100%;
        max-width: 320px;
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 20px #0000001A;
        border-radius: 6px;
        &.image-right {
          height: 105px;
          padding-right: 60px;
          max-width: 260px;
          @media(min-width: 1200px) {
            max-width: 320px;
          }
          img {
            position: absolute;
            top: 0px;
            right: 0%;
            margin-right: -52.5px;
          }
        }
        &.image-left {
          height: 105px;
          padding-left: 60px;
          max-width: 300px;
          @media(min-width: 1200px) {
            max-width: 320px;
          }
          img {
            position: absolute;
            top: 0px;
            left: 0%;
            margin-left: -52.5px;
          }
        }
        h3 {
          font: normal normal bold 12px/16px Lato;
          letter-spacing: 0px;
          color: #5DB035;
        }
        p {
          font: normal normal normal 12px/16px Lato;
          letter-spacing: 0px;
          color: #464646;
        }
      }
    }
    .pointer-right {
      position: relative;
      width: 100%;
      &:after {
        content: '';
        width: 50%;
        position: absolute;
        top:50%;
        right:0;
        height:1px;
        display: inline-block;
        background: #fff;
        z-index:1;
        opacity: 0.5;
      }
      &:before {
        content: '';
        position: absolute;
        right:-13px;
        top: 50%;
        margin-top: -13px;
        display: inline-block;
        background: url(/img/glowne-zalety/green-dot.svg) no-repeat;
        background-size: cover;
        height: 26px;
        width: 26px;
        z-index:1;
      }
    }
    .pointer-left {
      position: relative;
      width: 100%;
      &:after {
        content: '';
        width: 50%;
        position: absolute;
        top:50%;
        left:0;
        height:1px;
        display: inline-block;
        background: #fff;
        z-index:1;
        opacity: 0.5;
      }
      &:before {
        content: '';
        position: absolute;
        left:-13px;
        top: 50%;
        margin-top: -13px;
        display: inline-block;
        background: url(/img/glowne-zalety/green-dot.svg) no-repeat;
        background-size: cover;
        height: 26px;
        width: 26px;
        z-index:1;
      }
    }
  }
  .mobile {
    height: 100%;
    min-height: 650px;
    background: url(/img/glowne-zalety/bg-mobile.svg) no-repeat center;
    background-size: cover;
    .bg {
      background: url(/img/glowne-zalety/panel-mobile.png) no-repeat 45% 34px;
      padding-top: 330px;
    }
  }
  .btn {
    box-shadow: 0px 10px 20px #0000001A;
    border-radius: 6px;
    font: normal normal 600 16px/22px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #003554 url(/img/glowne-zalety/ico-pdf.svg) no-repeat 25px 10px;
    padding: 15px 25px 15px 72px;
  }

  .swiper-slide {
    .box-container {
      min-height: 270px;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: 5px;
      padding-right: 5px;
      .box {
        padding: 40px 35px;
        margin: 0 auto;
        width:100%;
        max-width: 320px;
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 20px #0000001A;
        border-radius: 6px;
        img {
          position: relative;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -52px;
        }
        h3 {
          text-align: center;
          font: normal normal bold 12px/16px Lato;
          letter-spacing: 0px;
          color: #5DB035;
        }
        p {
          text-align: center;
          font: normal normal normal 12px/16px Lato;
          letter-spacing: 0px;
          color: #464646;
        }
      }
    }
    .swiper-button-prev {
      background: url(/img/glowne-zalety/arrow-green-left.svg) no-repeat;
      left: -2px;
      top: 65%;
      position: absolute;
    }
    .swiper-button-next {
      background: url(/img/glowne-zalety/arrow-green-right.svg) no-repeat;
      right: -2px;
      top: 65%;
      position: absolute;
    }
  }
}