footer {
  background: #003554 0% 0% no-repeat padding-box;
  .row > * {
    min-height: 35px;
    @media(min-width: 992px) {
      min-height: 86px;
    }
    span, span a {
      font: normal normal normal 14px/17px Lato;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 0.7;
    }
  }
}