#home {
  @media(min-width: 992px) {
    margin-top: -75px;
    border-top: 4px solid #5DB035;
  }

  min-height: initial;
  position: relative;
  .overlay {
    background: url(/img/top/top-background.jpg) no-repeat center top;
    background-size: cover;
    max-height: 414px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #000000 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.4;
    }
  }

  h1 {
    font: normal normal bold 24px/30px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  h2 {
    font: normal normal normal 20px/26px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  h1, h2 {
    max-width: 290px;
    @media(min-width: 992px) {
      max-width: 100%;
    }
  }
  .image-panel {
    max-width: 267px;
    @media(min-width: 992px) {
      max-width: 332px;
    }
  }
  .image-to-left-bottom {
    left: 50%;
    bottom: 0;
    width: 28px;
    margin-left: -130px;
    @media(min-width: 992px) {
      width: 40px;
      margin-left: -170px;
    }
  }
}

#form-container {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-top: 4px solid #5DB035;

  @media(min-width: 992px) {
    min-height: 551px;
  }

  h2 {
    font: normal normal 600 20px/26px Montserrat;
    letter-spacing: 0px;
    color: #003554;
    @media(min-width: 992px) {
      font: normal normal 600 24px/30px Montserrat;
    }
  }

  input[type="text"], input[type="email"], textarea {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid #E3E7E9;
    border-radius: 4px;
    min-height: 44px;
  }
  .form-group {
    margin-bottom: 22px;
  }
  .checkbox-label {
    font: normal normal normal 14px/17px Lato;
    letter-spacing: 0px;
    color: #78838B;
  }
  a {
    font: normal normal normal 14px/17px Lato;
    letter-spacing: 0px;
    color: #003554;
  }
  input[type="submit"] {
    border:0;
    width: 100%;
    @media(min-width: 992px) {
      width: 160px;
    }
    height: 44px;
    background: #5DB035 0% 0% no-repeat padding-box;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  .disclaimer {
    font: normal normal normal 11px/13px Lato;
    letter-spacing: 0px;
    color: #9B9B9B;
  }
}

a.more {
  display: inline-block;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #003554;
  padding-right: 20px;
  background: url(/img/top/arrow-green-down.svg) no-repeat right center;
}